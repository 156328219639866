<template>
    <div>
        <v-card>
            <v-card-title>Información General Bitácoras</v-card-title>
            <v-card-text>
                <botonera 
                    :botones="botones" 
                    alinear="right"
                    @crear="create"
                    @cargar="upload = !upload"
                    @eliminar="remove"
                ></botonera>
            </v-card-text>
        </v-card>

        <datatable 
            :cabeceras="columns" 
            :items="data" 
            titulo="Listado de Bitácoras" 
            label="Escriba para buscar" 
            icono="list" 
            color_icono="azul" 
            :tachar="false"
            :acciones="['editar','eliminar','configurar']"
            @editar="edit($event)"
            @eliminar="remove($event)"
            @configurar="configurar($event)">
            
        </datatable>

        <!-- Modal Nueva Categoria -->
        <v-dialog
            v-model="dialog"
            width="800px"
            @click:outside = "closeModal"
        >
            <v-card>
                <v-card-title class="blue darken-1">{{ title }}</v-card-title>
                <v-card-text>
                    <form>
                        <v-row class="mx-2">
                            <v-col
                                class="align-center justify-space-between"
                                cols="8"
                            >
                                <v-text-field
                                    prepend-icon="mdi-barcode"
                                    placeholder="Nombre Bitácora"
                                    v-model="item.nombre"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="closeModal"
                    >Cerrar</v-btn>
                    <v-btn
                        color="primary"
                        @click="save"
                    >Grabar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- / Modal -->
    
        <v-snackbar
            v-model="snackbar"
            :timeout="5000"
            :color="color"
        >
            {{ validation_message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import DataTable from '@/components/base/DataTable.vue';

export default {
    data: () => ({
        title: 'Nueva Bitácora',
        color: 'green',
        snackbar: false,
        errors: [],
        validation_message: '',
        search: null,
        file: null,
        dialog: false,
        upload: false,
        changeState: false,
        parameters: [],
        item: {
            id: null,
            nombre: '',
        },
        columns: [
            {
                text: 'Id',
                align: 'center',
                sortable: true,
                value: 'id',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Nombre Bitácora',
                align: 'center',
                sortable: true,
                value: 'nombre',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Acciones',
                align: 'center',
                sortable: false,
                value: 'actions',
                class: "blue-grey darken-4 white-text"
            },
        ],
        data: [],
        botones:[
            {boton:'crear', tooltip:'Nueva Bitácora'},
        ]
    }),
    async mounted() {
        await this.loadParameters();
        await this.loadData();
    },
    methods:{
        async loadData(){
            let url = `${this.base_url}informacion_general`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.data = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadParameters(){
            let url = `${this.base_url}parametros`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.parametros = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async save(){
            let url = `${this.base_url}informacion_general`;
            if(this.item.id != null){
                url += `/${this.item.id}`;
                await this.axios.put(url, this.item, this.headers).then((response)=>{
                    let index = this.data.findIndex()
                    this.data.splice(index, 1, response.data);
                    this.showSnackBar(false);
                }).catch((error)=>{
                    this.validation_message = error;
                    this.showSnackBar(true);
                });
            }else{
                await this.axios.post(url, this.item, this.headers).then((response)=>{
                    this.data.push(response.data);
                    this.showSnackBar(false);
                }).catch((error)=>{
                    this.errors = error.response.data;
                    this.validation_message = this.errors.join();
                    this.showSnackBar(true);
                });
            }
            this.cleanForm();
        },
        create(){
            this.item.id = null;
            this.item.nombre = ''
            this.dialog = true;
        },
        edit(item) {
            //this.categoriaIndex = this.categoriasListBody.indexOf(item);
            this.item = Object.assign({}, item);
            this.title = "Editar";
            this.dialog = true;
        },
        configurar(item){
            this.$router.push({name:'informacion_general_bitacoras_configurar',params:{id:item.id}})
        },
        async remove(item) {
            this.$confirm('Desea borrar este tipo de bitacora?').then(res => {
                if(res){
                    let url = `${this.base_url}informacion_general/${item.id}`;
                    this.axios.delete(url, this.headers).then((response)=>{
                        console.log(response);
                        this.loadCategorias();
                        this.showSnackBar(false);
                    }).catch((error)=>{
                        this.validation_message = error;
                        this.showSnackBar(true);
                    });
                }
            });
        },
        showSnackBar(error){
            if(error){
                this.color = 'red';
            }else{
                this.color = 'green';
                this.validation_message = "Ejecutado exitosamente!";
            }
            this.snackbar = true;
        },
        closeModal(){
            this.cleanForm();
            this.dialog = false;
            this.error = null;
            this.title = "Nuevo";
        },
        cleanForm(){
            this.item.id = null;
            this.item.nombre = "";
            this.dialog = false;
        },
        ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
        ...mapState(['loading', 'base_url', 'headers']),
    },
    components:{
        'datatable': DataTable
    }
  }
</script>